import React,{useRef} from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {useHistory } from 'react-router-dom';
import {Link,browserHistory} from 'react-router';
import Parser from 'html-react-parser';
import ShopFilters from './components/filters';
import Subcategorii from './components/Subcategorii';
import LazyLoad from 'react-lazyload';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Popup from 'reactjs-popup';
import Product from './Produs';
import Homepage from './Homepage';
import ProductDetail from './ProdusDetail';
import MyLoader from './Skeleton';
import MyLoaderMobile from "./SkeletonMobile";
import MyLoaderCategory from "./SkeletonCategory";
import $ from 'jquery'; 
import { NumericFormat } from 'react-number-format';
import ColectieProduse from './components/colectieProduse';

class MyComponent extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {
      sel_prod: 0,
      prod_det: [],
      error: null,
      isLoaded: false,
      isBoxVisible:false,
      popContent:'',
      loadp:false,
      cat:window.category_id || 4104,
      prod_count:window.prod_count,
      crurl: window.location.href,
      loadedtp: 'categorie',
      items: [],
      colectie_poze: [],
      filtre: [],
      categorie: [],
      subcategorii: [],
    };
  }
  
  componentDidMount() {

        const {  cat, prod_count, isLoaded } = this.state;
       
        var produs_pop=0;
        var cat2=cat;
        //console.log(cat);
        if(window.produs_pop){
          produs_pop=window.produs_pop;
          
        }
        if(window.features_hash){
          window.features_hash=window.features_hash;
        }else{
          window.features_hash='';
        }

        if(window.sort_by){
          window.sort_by=window.sort_by;
        }else{
          window.sort_by='timestamp';
        }

        if(window.sort_order){
          window.sort_order=window.sort_order;
        }else{
          window.sort_order='desc';
        }
        if(window.lang){
          window.lang=window.lang;
        }else{
          window.lang='ro';
        }

        if(window.admin){
          window.admin=window.admin;
        }else{
          window.admin='nu';
        }

        if(window.camp_prod_id){
          window.camp_prod_id=window.camp_prod_id;
        }else{
          window.camp_prod_id='';
        }
       // window.comp_id=21;
        var url_s='list_prods.php?r=da';
        var url_p='products.php?r=da';
        if(window.comp_id){
          if(window.comp_id == 28 || window.comp_id == 29 || window.comp_id > 22  || window.comp_id == 6  || window.comp_id == 10  || window.comp_id == 21  || window.comp_id == 22  || window.comp_id == 20  || window.comp_id == 19){
            url_s='index.php?dispatch=react.list_prods';
            url_p='index.php?dispatch=react.product&comp_id='+window.comp_id;
          }else if(window.comp_id> 13 && window.comp_id< 19){
            url_s='list_prods_beezee.php?r=da';
          }else if(window.comp_id> 18){
            url_s='list_prods_costo.php?r=da';
          }
        }
        let loc=window.location.href;
        let locrec=loc.split('/p/');
        this.setState({
         crurl: locrec[0],
         url_s:url_s,
         url_p:url_p,
         produs_pop:produs_pop
 
       });
       var cat1 = localStorage.getItem("cat");
       
       //console.log(cat1);
       //console.log(window.category_id);
       // if(cat1!='' && cat1!='undefined' && cat1!=null){
       //   cat2=cat1;
       // }
       
      
       
       window.addEventListener("beforeunload", (event) => {
          //  event.preventDefault();
          //  console.log('Page Refreshed2');
          //  this.setState({
          //    isBoxVisible: false,

           // });
            console.log(locrec);
          //  window.history.pushState('product_id', 'Title', locrec[0]);
             
            //$('.mht').html('');
        });

        window.addEventListener('popstate', (event) => {
         // event.preventDefault();
          console.log(window.history);
          let crlcrl=window.location.href;
          let arep=crlcrl.split('.ht');
          const { crurl, isBoxVisible } = this.state;
          if (event.state) {
             
              console.log('backda');
              console.log(crurl);
              console.log(locrec);
              if(arep.length==2){
                console.log('e produs');
                let linkz1=window.location.href.split('/');
                let linkzs=window.location.href;
                let dtt=linkzs.split(window.location.origin+'/');
                let catnm=dtt[1].replace('/','');
                console.log(catnm);
                console.log(linkz1);
                let prddd=linkz1[(linkz1.length-1)];
                  
                $('.mht').html();
                this.setState({
                 isBoxVisible: false,
                 loadp: true,
                 loadedtp: 'product',
                 sel_prod: prddd,
                 prod_det: prddd,
               });
              
               console.log(prddd);
               //fetchData4(prddd, linkzs);
               
              }else{
                this.setState({
                  isBoxVisible: false,
                  loadedtp: 'categorie',
                  sel_prod: 0,
                  //prod_det: [],
                });
                $('.mht').html('');
                console.log(event);
                let linkz=window.location.href;
                let linkzs=window.location.href;
                let dtt=linkz.split(window.location.origin+'/');
                let catnm=dtt[1].replace('/','');
                console.log(catnm);
      
                //    window.history.pushState(catnm, 'Title', linkz);
                $("link[href*='styleall']").prop('disabled', true);
                $("link[href*='styleall']").remove();

                $('html').css('overflow', '');
                $('html').css('overflow-x', 'hidden');
                      fetchData2(catnm, linkzs);
              }
              
          }else{
            console.log('backda1');
            console.log(crurl);
            console.log(isBoxVisible);
             
              $("link[href*='styleall']").prop('disabled', true);
              $("link[href*='styleall']").remove();

              $('html').css('overflow', '');
              $('html').css('overflow-x', 'hidden');
              if(window.innerWidth>768 || isBoxVisible==false){
                let linkz=window.location.href;
                let linkzs=window.location.href;
                let dtt=linkz.split(window.location.origin+'/');
                let catnm=dtt[1].replace('/','');
                console.log(catnm);
                fetchData2(catnm, linkzs);
                
              }
              this.setState({
                isBoxVisible: false,
                loadedtp: 'categorie',
              });
            //  window.history.pushState('product_id', 'Title', locrec[0]);
              //console.log(locrec[0]);
            //  $('.mht').html('');
          }
        //  event.preventDefault();
         }, false);

       // window.history.pushState({name: "browserBack"}, "on browser back click",loc);
        //window.history.pushState({name: "browserBack"}, "on browser back click", loc);
        let sent=0;


        const doLinkChange = async(event) => {
                           
          event.preventDefault();
          $('.mht').html('');
          $('.tygh-header').removeClass('menu-active');
          $('body').css('position','relative');
          this.setState({
            isBoxVisible: false,
            loadedtp: 'categorie',
            sel_prod: 0,
            prod_det: [],
          });
         
          let linkz=event.target.href;
          let linkzs=window.location.href;
          let dtt=linkz.split(window.location.origin+'/');
          let catnm=dtt[1].replace('/','');
          //console.log(catnm);

          $("link[href*='styleall']").prop('disabled', true);
          $("link[href*='styleall']").remove();

          window.history.pushState(catnm, 'Title', linkz);
          
                fetchData2(catnm, linkzs);
               
          
      }

      const doHome = async(event) => {
                           
        event.preventDefault();
        $('.mht').html('');
        $('.tygh-header').removeClass('menu-active');
        $('body').css('position','relative');
        this.setState({
          isBoxVisible: false,
          loadedtp: 'homepage',
          sel_prod: 0,
          prod_det: [],
        });
        
        var file = "js/styleall.css";
                          
        var link = document.createElement( "link" );
        link.href = file.substr( 0, file.lastIndexOf( "." ) ) + ".css";
        link.type = "text/css";
        link.rel = "stylesheet";
        link.media = "screen,print";

        document.getElementsByTagName( "head" )[0].appendChild( link );

        let catnm='home';
        if(window.comp_id==28){
          window.history.pushState(catnm, 'Title', window.location.origin+'/ua/');
        }else if(window.comp_id==29){
          window.history.pushState(catnm, 'Title', window.location.origin+'/de/');
        }else if(window.comp_id==23){
          window.history.pushState(catnm, 'Title', window.location.origin+'/es/');
        }else{
          window.history.pushState(catnm, 'Title', window.location.origin);
        }
        
        
          //    fetchData2(catnm, linkzs);
             
        
    }

     
      var elements = document.getElementsByClassName("ty-menu__submenu-link");
      Array.from(elements).forEach(function(element) {
          element.removeEventListener('click', (e)=>doLinkChange(e), false);
       
      });

      var elements = document.getElementsByClassName("ty-menu__submenu-link");
      Array.from(elements).forEach(function(element) {
          element.addEventListener('click', (e)=>doLinkChange(e), false);
       
      });

      

      var elements = document.getElementsByClassName("ty-logo-container");
      Array.from(elements).forEach(function(element) {
       //   element.addEventListener('click', (e)=>doHome(e), false);
       
      });

      var elements = document.getElementsByClassName("slda");
      Array.from(elements).forEach(function(element) {
      
        element.addEventListener('click', (e)=>doLinkChange(e), false);
      });
       
       /* 
      if(localStorage.getItem("appState"+cat)){
          var sts = JSON.parse(localStorage.getItem("appState"+cat))
          console.log(this.state);
          this.setState(sts);
      }else{
        */
       
          const fetchData = async () => {
           // console.log(''+url_s+'?category_id='+cat2+'&features_hash='+window.features_hash+'&sort_by='+window.sort_by+'&sort_order='+window.sort_order+'&lang_code='+window.lang+'&camp_prod_id='+window.camp_prod_id+'&comp_id='+window.comp_id);
          await fetch(''+url_s+'&category_id='+cat2+'&features_hash='+window.features_hash+'&sort_by='+window.sort_by+'&sort_order='+window.sort_order+'&lang_code='+window.lang+'&camp_prod_id='+window.camp_prod_id+'&comp_id='+window.comp_id) 
                    .then(res => res.json())
                    .then(
                      (result) => {
                        this.setState({
                          isLoaded: true,
                          items: result.items,
                          colectie_poze: result?.colectii_produse,
                          count: result.count*159,
                          countz: result.count,
                          filtre: result.filters,
                          categorie: result.category,
                          subcategorii: result.subcategories,
                          lang_code: window.lang,
                          isLoggedIn: window.isLoggedIn,
                          admin: window.admin,
                          cat: cat
                        });
                        localStorage.setItem( 'items', JSON.stringify(result.items) );
                        localStorage.setItem( 'cat', cat2 );
                        //console.log(result);
                      // localStorage.setItem('appState'+cat, JSON.stringify(this.state));
                      //console.log(produs_pop);
                            if(produs_pop){
                              if(window.innerWidth<768){
                                //console.log(produs_pop);
                                //console.log('dasa');
                                  /*
                                  $.getJSON("index.php?dispatch=products.quick_view&product_id="+produs_pop+"", { is_ajax: 1}, function(data){
                                              $('.mht').html(data.text);
                                          });  */
                                          
                                          $('.mht').html(<Product product_id={produs_pop} />);
                                // this.props.history.replace({ pathname: `/product/${this.props.product.id}`})
                                window.history.pushState(produs_pop, 'Title', window.location.href);
                                this.setState({
                                  isBoxVisible: true,
                                });
                            
                              }
                            }

                           

                            
                            
                      },
                      // Note: it's important to handle errors here
                      // instead of a catch() block so that we don't swallow
                      // exceptions from actual bugs in components.
                      (error) => {
                        this.setState({
                          isLoaded: true,
                          isBoxVisible: false,
                          error
                        });
                      }
                    )
                  };
                  
                  fetchData();

                  const fetchData2 = async (catname, linkz) => {

                    this.setState({
                      isLoaded: false
                    });

                    await fetch(''+url_s+'&category_name='+catname+'&sort_by='+window.sort_by+'&sort_order='+window.sort_order+'&lang_code='+window.lang+'&camp_prod_id='+window.camp_prod_id+'&comp_id='+window.comp_id) 
                    .then(res => res.json())
                    .then(
                      (result) => {
                        this.setState({
                          isLoaded: true,
                          items: result.items,
                          colectie_poze: result?.colectii_produse,
                          filtre: result.filters,
                          categorie: result.category,
                          subcategorii: result.subcategories,
                          count: result.count*159,
                          countz: result.count,
                          lang_code: window.lang,
                          isLoggedIn: window.isLoggedIn,
                          admin: window.admin,
                          cat: result?.category?.category_id
                        });
                        //console.log(result);
                        //console.log(result.filters);
                        localStorage.setItem( 'items', JSON.stringify(result.items) );
                        localStorage.setItem( 'cat', result.category.category_id );
                      // localStorage.setItem('appState'+cat, JSON.stringify(this.state));
                      //console.log(produs_pop);
                            if(produs_pop){
                              if(window.innerWidth<768){
                                //console.log(produs_pop);
                                //console.log('dasa');
                                  /*
                                  $.getJSON("index.php?dispatch=products.quick_view&product_id="+produs_pop+"", { is_ajax: 1}, function(data){
                                              $('.mht').html(data.text);
                                          });  
                                          */
                                          $('.mht').html(<Product product_id={produs_pop} />);
                                // this.props.history.replace({ pathname: `/product/${this.props.product.id}`})
                                window.history.pushState(produs_pop, 'Title', window.location.href);
                                this.setState({
                                  isBoxVisible: true,
                                });
                            
                              }
                            }
                            //console.log(linkz);
                            this.setState({
                              crurl: linkz,
                              loc: linkz,
                            });
                            
                      },
                      // Note: it's important to handle errors here
                      // instead of a catch() block so that we don't swallow
                      // exceptions from actual bugs in components.
                      (error) => {
                        this.setState({
                          isLoaded: true,
                          isBoxVisible: false,
                          error
                        });
                      }
                    )
                  }

                  const fetchData3 = async (catid, linkz) => {

                    this.setState({
                      isLoaded: false
                    });
                    //console.log(''+url_s+'?category_id='+catid+'&sort_by='+window.sort_by+'&sort_order='+window.sort_order+'&lang_code='+window.lang+'&camp_prod_id='+window.camp_prod_id+'&comp_id='+window.comp_id);
                    await fetch(''+url_s+'&category_id='+catid+'&sort_by='+window.sort_by+'&sort_order='+window.sort_order+'&lang_code='+window.lang+'&camp_prod_id='+window.camp_prod_id+'&comp_id='+window.comp_id) 
                    .then(res => res.json())
                    .then(
                      (result) => {
                        this.setState({
                          isLoaded: true,
                          items: result.items,
                          colectie_poze: result.colectii_produse,
                          filtre: result.filters,
                          categorie: result.category,
                          subcategorii: result.subcategories,
                          count: result.count*159,
                          countz: result.count,
                          lang_code: window.lang,
                          isLoggedIn: window.isLoggedIn,
                          admin: window.admin,
                          cat: result.category.category_id
                        });
                        //console.log(result);
                        //console.log(result.filters);
                        localStorage.setItem( 'items', JSON.stringify(result.items) );
                        localStorage.setItem( 'cat', result.category.category_id );
                      // localStorage.setItem('appState'+cat, JSON.stringify(this.state));
                      //console.log(produs_pop);
                            if(produs_pop){
                              if(window.innerWidth<768){
                                //console.log(produs_pop);
                                //console.log('dasa');
                                  /*
                                  $.getJSON("index.php?dispatch=products.quick_view&product_id="+produs_pop+"", { is_ajax: 1}, function(data){
                                              $('.mht').html(data.text);
                                          });  
                                          */
                                          $('.mht').html(<Product product_id={produs_pop} />);
                                // this.props.history.replace({ pathname: `/product/${this.props.product.id}`})
                                window.history.pushState(produs_pop, 'Title', window.location.href);
                                this.setState({
                                  isBoxVisible: true,
                                });
                            
                              }
                            }
                            //console.log(linkz);
                            this.setState({
                              crurl: linkz,
                            });
                            
                      },
                      // Note: it's important to handle errors here
                      // instead of a catch() block so that we don't swallow
                      // exceptions from actual bugs in components.
                      (error) => {
                        this.setState({
                          isLoaded: true,
                          isBoxVisible: false,
                          error
                        });
                      }
                    )
                  }

                  const fetchData4 = async (prodid, linkz) => {

                    if(window.innerWidth<768){
                      console.log(prodid);
                      console.log(linkz);
                      
                        /*
                        $.getJSON("index.php?dispatch=products.quick_view&product_id="+product_id+"", { is_ajax: 1}, function(data){
                                    $('.mht').html(data.text);
                                });  
                                */
                                $('.mht').html();
            
                              await fetch(url_p+'&product_id='+prodid) 
                                  .then(res => res.json())
                                  .then(
                                    (result) => {
                                      
                                      //console.log(result);
                                      this.setState({
                                        sel_prod: prodid,
                                        prod_det: result,
                                        loadp: true
                                      });
                                      
            
                                      var file = "js/styleall.css";
            
                                      var link = document.createElement( "link" );
                                      link.href = file.substr( 0, file.lastIndexOf( "." ) ) + ".css";
                                      link.type = "text/css";
                                      link.rel = "stylesheet";
                                      link.media = "screen,print";
            
                                      document.getElementsByTagName( "head" )[0].appendChild( link );
                                     
                                    },
                                    // Note: it's important to handle errors here
                                    // instead of a catch() block so that we don't swallow
                                    // exceptions from actual bugs in components.
                                    (error) => {
                                      //console.log(error)
                                    }
                                  )
            
                                  this.setState({
                                    isBoxVisible: true,
                                    loadp: false
                                  });
                                        
                                
                       // this.props.history.replace({ pa
                    }else{
                      //browserHistory.push(url);
                      
                        /*
                        $.getJSON("index.php?dispatch=products.quick_view&product_id="+product_id+"", { is_ajax: 1}, function(data){
                                    $('.mht').html(data.text);
                                });  
                                */
                                $('.mht').html();
                               this.setState({
                                isBoxVisible: false,
                                loadp: false
                              });
                               
                              await fetch(url_p+'&product_id='+prodid) 
                                  .then(res => res.json())
                                  .then(
                                    (result) => {
                                      
                                      //console.log(result);
                                      this.setState({
                                        sel_prod: result.product_id,
                                        prod_det: result,
                                       
                                        loadedtp:'product',
                                        loadp: true
                                      });
                                      
                                     
                                    },
                                    // Note: it's important to handle errors here
                                    // instead of a catch() block so that we don't swallow
                                    // exceptions from actual bugs in components.
                                    (error) => {
                                      //console.log(error)
                                    }
                                  )
                                
                       // this.props.history.replace({ pathname: `/product/${this.props.product.id}`})
                      // window.history.pushState(product_id, 'Title', url);
                      
                  
                    }
                  }
         
                 
              //}
  }

  componentWillUnmount() {
    console.log('unmount');
    var elements = document.getElementsByClassName("ty-menu__submenu-link");
    Array.from(elements).forEach(function(element) {
      element.removeEventListener('click', async (event) => {
        event.preventDefault();
        
    
      }, false);
    });

    var elements = document.getElementsByClassName("ty-menu__item-link");
    Array.from(elements).forEach(function(element) {
      element.removeEventListener('click', async (event) => {
        event.preventDefault();
        
      }, false);
    });
  }

  
          
      

  render() {
   
    const { error, isLoaded, isBoxVisible,loadedtp, popContent,prod_count, items,colectie_poze, cat, url_s,url_p, produs_pop,count,crurl,sel_prod,loadp,prod_det,filtre,categorie,subcategorii, lang_code,isLoggedIn, admin } = this.state;
    
    const filter = async (feature_hash) => {
      //console.log(feature_hash);
      const fetchData = async () => {

        this.setState({
          isLoaded: false
        });

        //console.log(''+url_s+'?category_id='+cat+'&sort_by='+window.sort_by+'&sort_order='+window.sort_order+'&lang_code='+window.lang+'&camp_prod_id='+window.camp_prod_id+'&comp_id='+window.comp_id+'&'+feature_hash);
        await fetch(''+url_s+'&category_id='+cat+'&sort_by='+window.sort_by+'&sort_order='+window.sort_order+'&lang_code='+window.lang+'&camp_prod_id='+window.camp_prod_id+'&comp_id='+window.comp_id+'&'+feature_hash) 
                  .then(res => res.json())
                  .then(
                    (result) => {
                      this.setState({
                        isLoaded: true,
                        items: result.items,
                        colectie_poze: result.colectii_produse,
                        filtre: result.filters,
                        categorie: result.category,
                          subcategorii: result.subcategories,
                        count: result.count*159,
                        countz: result.count,
                        lang_code: window.lang,
                        isLoggedIn: window.isLoggedIn,
                        admin: window.admin,
                        cat: cat
                      });
                      //console.log(result.items);
                      //console.log(result.filters);
                      localStorage.setItem( 'items', JSON.stringify(result.items) );
                      localStorage.setItem( 'cat', cat );
                    // localStorage.setItem('appState'+cat, JSON.stringify(this.state));
                    //console.log(produs_pop);
                          if(produs_pop){
                            if(window.innerWidth<768){
                              //console.log(produs_pop);
                              //console.log('dasa');
                                /*
                                $.getJSON("index.php?dispatch=products.quick_view&product_id="+produs_pop+"", { is_ajax: 1}, function(data){
                                            $('.mht').html(data.text);
                                        });  
                                        */
                                        $('.mht').html(<Product product_id={produs_pop}/>);
                              // this.props.history.replace({ pathname: `/product/${this.props.product.id}`})
                              window.history.pushState(produs_pop, 'Title', window.location.href);
                              this.setState({
                                isBoxVisible: true,
                              });
                          
                            }
                          }
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                      this.setState({
                        isLoaded: true,
                        isBoxVisible: false,
                        error
                      });
                    }
                  )
                };
                
                fetchData();
    }

    const doLinkChange = async (catname) => {
      //console.log(catname);
      const fetchData = async () => {

        this.setState({
          isLoaded: false
        });

        //console.log(''+url_s+'?category_name='+catname+'&sort_by='+window.sort_by+'&sort_order='+window.sort_order+'&lang_code='+window.lang+'&camp_prod_id='+window.camp_prod_id+'&comp_id='+window.comp_id);
        await fetch(''+url_s+'&category_name='+catname+'&sort_by='+window.sort_by+'&sort_order='+window.sort_order+'&lang_code='+window.lang+'&camp_prod_id='+window.camp_prod_id+'&comp_id='+window.comp_id) 
                  .then(res => res.json())
                  .then(
                    (result) => {
                      this.setState({
                        isLoaded: true,
                        items: result.items,
                        colectie_poze: result.colectii_produse,
                        filtre: result.filters,
                        categorie: result.category,
                        subcategorii: result.subcategories,
                        count: result.count*159,
                        countz: result.count,
                        lang_code: window.lang,
                        isLoggedIn: window.isLoggedIn,
                        admin: window.admin,
                        cat: result.category.category_id 
                      });
                      //console.log(result.items);
                      //console.log( result.category.category_id );
                      //console.log(result);
                      localStorage.setItem( 'items', JSON.stringify(result.items) );
                      localStorage.setItem( 'cat', result.category.category_id  );

                    // localStorage.setItem('appState'+cat, JSON.stringify(this.state));
                    //console.log(produs_pop);
                          if(produs_pop){
                            if(window.innerWidth<768){
                              //console.log(produs_pop);
                              //console.log('dasa');
                                /*
                                $.getJSON("index.php?dispatch=products.quick_view&product_id="+produs_pop+"", { is_ajax: 1}, function(data){
                                            $('.mht').html(data.text);
                                        });  
                                        */
                                        $('.mht').html(<Product product_id={produs_pop} moneda={moneda}/>);
                              // this.props.history.replace({ pathname: `/product/${this.props.product.id}`})
                              window.history.pushState(produs_pop, 'Title', window.location.href);
                              this.setState({
                                isBoxVisible: true,
                              });
                          
                            }
                          }
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                      this.setState({
                        isLoaded: true,
                        isBoxVisible: false,
                        error
                      });
                    }
                  )
                };
                
                fetchData();
    }

    const doSaveData = async (product_id) => {
      try {
        //console.log(product_id);
        var data_noua= $( ".data_noua_"+product_id ).val();
        //console.log(data_noua);
          $.getJSON("index.php?dispatch=categories.save_datanoua", { prid: product_id, data_noua: data_noua, page_limit: 1, page: 1 }, function(data){
                      
           });  
      } catch (err) {
        //console.log(err);
      }
    };

    const doSaveColId = async (product_id) => {
      try {
        //console.log(product_id);
        var colectie= $( ".colectie_id_"+product_id ).val();
        //console.log(colectie);
          $.getJSON("index.php?dispatch=categories.save_colectie_id", { prid: product_id, colectie: colectie, page_limit: 1, page: 1 }, function(data){
                      
                  });  
      } catch (err) {
        //console.log(err);
      }

      
    };

    const doSaveFurnizor = async (product_id) => {
      try {
        //console.log(product_id);
        var colectie= $( ".colectie_id_"+product_id ).val();
        //console.log(colectie);
          $.getJSON("index.php?dispatch=categories.save_furnizor_nu", { prid: product_id, colectie: colectie, page_limit: 1, page: 1 }, function(data){
                      
                  });  
      } catch (err) {
        //console.log(err);
      }

      
    };

    const doDeleteNoutati = async (product_id) => {
      try {
           $.getJSON("index.php?dispatch=categories.delete_noutati", { prid: product_id, page_limit: 1, page: 1 }, function(data){
                      
                  });  
      } catch (err) {
        //console.log(err);
      }

      
    };

    const doMoveBeezee = async (product_id) => {
      try {
           $.getJSON("index.php?dispatch=categories.muta_deezee", { prid: product_id, page_limit: 1, page: 1 }, function(data){
                      
                  });  
      } catch (err) {
        //console.log(err);
      }

      
    };

    const doSaveCol = async (product_id) => {
      try {
        //console.log(product_id);
        var colectie= $( ".colectie_"+product_id ).val();
        //console.log(colectie);
          $.getJSON("index.php?dispatch=categories.save_colectie", { prid: product_id, colectie: colectie, page_limit: 1, page: 1 }, function(data){
                      
                  });  
      } catch (err) {
        //console.log(err);
      }
  };

    let moneda;
    let crl;
    
    const doPixel = (product, product_id, colctia, price) => {
        
        window.fbq('track', 'ViewContent', { 
          content_type: 'product_group',
          content_ids: [colctia],
          content_name: product,
          value: price,
          currency: moneda, 
      });
    }
    
    const openTooltip = async (event, product_id, url,image) => {
      
        if(window.innerWidth<768){
          event.preventDefault();
          console.log(product_id);
          console.log(url);
          this.setState({
            loadp: false
          });
            /*
            $.getJSON("index.php?dispatch=products.quick_view&product_id="+product_id+"", { is_ajax: 1}, function(data){
                        $('.mht').html(data.text);
                    });  
                    */
                    $('.mht').html();

                  await fetch(url_p+'&product_id='+product_id) 
                      .then(res => res.json())
                      .then(
                        (result) => {
                          
                          //console.log(result);
                          this.setState({
                            sel_prod: product_id,
                            prod_det: result,
                            imfirst: image,
                            loadp: true
                          });
                          

                          var file = "js/styleall.css";
                          
                          var link = document.createElement( "link" );
                          link.href = file.substr( 0, file.lastIndexOf( "." ) ) + ".css";
                          link.type = "text/css";
                          link.rel = "stylesheet";
                          link.media = "screen,print";

                          document.getElementsByTagName( "head" )[0].appendChild( link );


                          var file = "js/checkout.css";
                          
                          var link = document.createElement( "link" );
                          link.href = file.substr( 0, file.lastIndexOf( "." ) ) + ".css";
                          link.type = "text/css";
                          link.rel = "stylesheet";
                          link.media = "screen,print";

                          document.getElementsByTagName( "head" )[0].appendChild( link );

                          $('html').css('overflow', 'hidden');
                         
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                          //console.log(error)
                        }
                      )

                      this.setState({
                        isBoxVisible: true,
                        loadp: false
                      });
                            
                    
           // this.props.history.replace({ pathname: `/product/${this.props.product.id}`})
           window.history.pushState(product_id, 'Title', url.replace('https://zapatos.md',''));
          
         event.preventDefault();
        }else{
          //browserHistory.push(url);
          document.getElementsByClassName('breadcrumbs-grid')[0].style.display = 'none';
          event.preventDefault();
          //console.log(product_id);
          
            /*
            $.getJSON("index.php?dispatch=products.quick_view&product_id="+product_id+"", { is_ajax: 1}, function(data){
                        $('.mht').html(data.text);
                    });  
                    */
                    $('.mht').html();
                   this.setState({
                    isBoxVisible: false,
                    loadp: false
                  });
                   
                  await fetch(url_p+'&product_id='+product_id) 
                      .then(res => res.json())
                      .then(
                        (result) => {
                          
                          //console.log(result);
                          this.setState({
                            sel_prod: product_id,
                            prod_det: result,
                            imfirst: image,
                            imfirst: image,
                            loadedtp:'product',
                            loadp: true
                          });
                          
                         
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                          //console.log(error)
                        }
                      )
                    
           // this.props.history.replace({ pathname: `/product/${this.props.product.id}`})
           window.history.pushState(product_id, 'Title',  url.replace('https://zapatos.md',''));
          
         event.preventDefault();
          event.preventDefault();
        }
        //console.log(window.innerWidth);

    }

    const closeTooltip = (event, product_id) => {
      //console.log(product_id);
      
      this.setState({
        isBoxVisible: false,
        sel_prod: 0,
       // prod_det: [],
        loadp: false
      });

      $("link[href*='styleall']").prop('disabled', true);
      $("link[href*='styleall']").remove();
      
      $('html').css('overflow', '');
      $('html').css('overflow-x', 'hidden');
      $('html').css('overflow-x', 'hidden');

      window.history.pushState(product_id, 'Title', crurl);
      //console.log(window.location.href);
      //console.log(crurl);
      //$('.mht').html('');
     event.preventDefault();

}

   

    if(window.moneda!=''){
      moneda=window.moneda;
      crl=2.25;
    }else if(lang_code=='bg'){
      moneda='лв';
      crl=2.25;
    }else if(lang_code=='hu'){
      moneda='Ft';
      crl=0.0127;
    }else if(lang_code=='el'){
      moneda='€';
      crl=4.2;
    }else if(lang_code=='it'){
      moneda='€';
      crl=4.2;
    }else if(lang_code=='sl'){
      moneda='€';
      crl=4.2;
    }else if(lang_code=='sk'){
      moneda='€';
      crl=4.2;
    }else if(lang_code=='fr'){
      moneda='€';
      crl=4.2;
    }else if(lang_code=='hr'){
      moneda='€';
      crl=0.6;
    }else if(lang_code=='pl'){
      moneda='zl';
      crl=1;
    }else if(lang_code=='cs'){
      moneda='Kč';
      crl=1;
    }else{
      moneda='lei';
      crl=1;
    }

    $('.ty-column5777 a').on('click',function(e){
    
      if ( $(this).children().children('.culori_colectii').children().length > 1 ) {
          e.preventDefault();
      
       $('.produse').html('');
      
      var idcol=$(this).children().children().attr('data-id');
     
      $.get("index.php?dispatch=products.produse_colectie", { mode: 'produse_colectie' , idcol:idcol  }, function(data1){ 
          var objs = JSON.parse( data1);
          var html='<h5>'+objs.titlu+'</h5>';
          //console.log(objs);
          $.each(objs.items, function(i, item) {
                   html=html+'<a href="index.php?dispatch=products.view&product_id='+item.product_id+'"><div className="sh_item"><img src="'+item.image+'"><span className="sh_title">'+item.culoare+'</span><span className="sh_price">'+item.price+' lei</span></div></a>';
             
          });
          $('.produse').html(html);
  
      });
      $('#tygh_container').addClass('colectie-active');
      var lnk1=$(this).attr('href');
        setTimeout(function(){
              //$('.span13.main-content-grid').removeClass('colectie-active');
              document.location.href=lnk1;
          }, 3000);
      }
  });
  $('.colectie-close').on('click',function(e){
      e.preventDefault();
      $('#tygh_container').removeClass('colectie-active');
  });

  const isLoggedIn1='true';

    const rows = [];
    for (var i = 1; i < prod_count; i++) {
        rows.push(
         <>
          {window.innerWidth<768 ?
            <div style={{display:"inline-block", width:"50%", padding:"0 10px", boxSizing:"border-box"}}>
              <MyLoaderMobile style={{width:"100%"}}/></div>
            :
          <div style={{display:"inline-block", width:"25%", padding:"0 10px", boxSizing:"border-box"}} >
          <MyLoader style={{width:"100%"}}/></div>
          }
          </>
          );
    }
    if (error) {
      return <div>Error: {error.message}</div>;
    }else if(isLoaded===false){
      return  <><div className="grid-list ">
        <div style={{display:"block", width:"80%", padding:"50px 0 20px 10px", margin: "0 auto"}}>
              <MyLoaderCategory style={{width:"100%"}}/>
        </div>
        {rows}
        </div></>;
    } else {
      return (
        <>
        {loadedtp=="product" ?

        <>
        <ProductDetail product_id={sel_prod} product={prod_det} moneda={moneda}/>
        </>
        : loadedtp=="homepage" ?
        <>
        <Homepage />
        </>
        :
        <>
        <div className='span16 breadcrumbs-grid'>
        {Parser(categorie.breadcrumbs.toString())}
        <h1 className="ty-mainbox-title">
          <span>{categorie?.category}</span>
          </h1>
          </div>
          <Subcategorii subcategorii={subcategorii}  doLinkChange={doLinkChange}/>

        <div className="grid-list ">
           <ShopFilters filtre={filtre} filter={filter} />
          <div className="">
          
          {items.map(item => (
           
            <div className={`ty-column5  ${item.produsul_zilei} `}>
            <LazyLoad key={item.product_id} offset={300}>

            <form action="" method="post" name="product_form_97827" enctype="multipart/form-data" className="cm-disable-empty-files cm-ajax cm-ajax-full-render cm-ajax-status-middle cm-processed-form">
              <input type="hidden" name="result_ids" value="cart_status*,wish_list*,checkout*,account_info*"/>
              <input type="hidden" name="redirect_url" value={`index.php?dispatch=categories.view&category_id=${window.category_id}`}/>
              <input type="hidden" name={`product_data[${item.product_id}][product_id]`} value={item.product_id}/>
              <input type="hidden" name="security_hash" className="cm-no-hide-input" value="02f3d5b93a94313977afb80c54b4e9b5"/>
              <a className="ty-btn ty-btn__text cm-ajax ty-add-to-wish cm-submit text-button1" id={`button_wishlist_${item.product_id}`} href={`/index.php?dispatch=wishlist.add&product_id=${item.product_id}&is_ajax=1`}></a>
            </form>         
            <a href={item.url} onClick={e => openTooltip(e,item.product_id,item.url,item.image_link)}>
            
              <div className={`ty-grid-list__item ty-quick-view-button__wrapper a${item.colectie} ty-grid-list__item--overlay`}>
                    <div className="ty-grid-list__image" data-id={item.colectie}>
                    <LazyLoadImage  src={`${item.image_link}`} delayTime={10} alt={item.product} onLoad={e => doPixel(item.product, item.product_id, item.colectie, item.price)} afterLoad={e => doPixel(item.product, item.product_id, item.colectie, item.price)}/>
                     <div className={`ty-product-labels ty-product-labels--top-right   cm-reload-${item.product_id}`} id={`product_labels_update_${item.product_id}`}>
                     {item.discount > 0 && (
                          <div className="ty-product-labels__item   ty-product-labels__item--discount">
                            <div className="ty-product-labels__content">{Math.round(((item.price*100/item.list_price)-100).toString().replace("-", ""))}</div>
                          </div>
                       )}  
                      </div>
                   
                    </div>
                    {Parser(item.promotii.toString())}
                      {window.comp_id==21 || window.comp_id==20 || window.comp_id==22 || window.comp_id==19  || window.comp_id==31 ?
                      <>
                      <ColectieProduse items={item.colectii_produse} />
                      </>
                      :
                      <>
                     {Parser(item.culori)}
                      </>
                      }
                    
                    
                    <div className="ty-grid-list__item-name"><bdi>
                    {item.tip_produs!==null && item.tip_produs!=='' && (
                        <span className={`top  ${item.tip_produs} `}>{item.tip_produs}</span>
                    )}
                    {item.brand!==null && (
                        <span>{item.brand}</span>
                    )}
                     {item.product}
                      </bdi>
                    </div>
                    <div className="grid-list__rating">    
                    </div>
                    <div className="ty-grid-list__price ">
                    {item.list_price > 0 && (
                      <span className={`cm-reload-${item.product_id}`} id={`old_price_update_${item.product_id}`}>
                          <span className="ty-list-price ty-nowrap" id={`line_list_price_${item.product_id}`}><span className="ty-strike"><bdi><span id="sec_list_price_{item.product_id}" className="ty-list-price ty-nowrap">{item.list_price.split('.')[0]}<sup>{item.list_price.split('.')[1]}</sup></span>&nbsp;<span className="ty-list-price ty-nowrap">{moneda}</span></bdi></span></span>
                        
                      </span>
                       )} 
                      <span className={`cm-reload-${item.product_id} ty-price-update`} id={`price_update_${item.product_id}`}>
                          <input type="hidden" name="appearance[show_price_values]" value="1"/>
                          <input type="hidden" name="appearance[show_price]" value="1"/>
                          {item.list_price > 0 && (
                          <span className="ty-price red" id={`line_discounted_price_${item.product_id}`}><bdi><span id={`sec_discounted_price_${item.product_id}`} className="ty-price-num"><NumericFormat
                                    value={item?.price?.split(".")[0]}
                                    thousandSeparator=" "
                                    displayType="text"
                                  /><sup>{item.price.split('.')[1]}</sup></span>&nbsp;<span className="ty-price-num">{moneda}</span></bdi></span>
                          )} 
                          {item.list_price < 1 && (
                          <span className="ty-price" id={`line_discounted_price_${item.product_id}`}><bdi><span id={`sec_discounted_price_${item.product_id}`} className="ty-price-num"><NumericFormat
                                    value={item?.price?.split(".")[0]}
                                    thousandSeparator=" "
                                    displayType="text"
                                  /><sup>{item.price.split('.')[1]}</sup></span>&nbsp;<span className="ty-price-num">{moneda}</span></bdi></span>
                          )} 
                      </span>
                    </div>
                    <div className="marimi">{item.caracteristici}</div>
                   
                    
              </div>
            </a>
            {isLoggedIn==='true' && admin==='da' && (
              
              <span className="colls">
                  Cod: <b >{item.product_code}</b><br />
                  Stoc: <b className="stc1" >{item.stoc_total}</b><br />
                  Colectie:  <a href={`/culori_categorie_nou.php?colectie=${item.colectie}`} target="_blank"><b className="stc1" >{item.colectie}</b></a><br />
                  Colectie ID: <b className="stc1" >{item.colectie_id}</b><br />
                  Adaos: <b className="stc1" >{item.adaos}</b><br />
                  Data: <b className="dataprod" >{item.data_produs}</b><br />
                  ID Produs: <b className="dataprod" >{item.product_id}</b><br />
                  Vanzari 7/14: <b className="dataprod" >{item.vanzari_7_zile}/{item.vanzari_14_zile}</b><br />
              </span>
               )}
             {isLoggedIn==='true' && admin==='da' && (
              <div className="collm">
                  <input type="text"  className={`data_noua_${item.product_id}`} />
                  <button onClick={() => doSaveData(item.product_id)}  onclick="doSaveData({item.product_id})" >Save Data</button>
                  <br/>
                  <input type="text" className={`colectie_id_${item.product_id}`} />
                  <button onClick={() => doSaveColId(item.product_id)} className={`colectie_save_${item.product_id}`} className={`data_noua_${item.product_id}`}  onclick="doSaveColId({item.product_id})">Save Colectie Id</button>
                  <br/>
                  <input type="checkbox" className={`colectie_id_${item.product_id}`} />
                  <button onClick={() => doSaveFurnizor(item.product_id)} className={`colectie_save_${item.product_id}`} className={`data_noua_${item.product_id}`}  onclick="doSaveFurnizor({item.product_id})">Save Fara Stoc Furnizor</button>
                  <br/>
                  {window.category_id=='547' && (
                  <button onClick={() => doDeleteNoutati(item.product_id)} className={`colectie_save_${item.product_id}`} className={`data_noua_${item.product_id}`}  onclick="doDeleteNoutati({item.product_id})">Sterge Din Noutati</button>
                  )}
                  <br/>
                  <button onClick={() => doMoveBeezee(item.product_id)} className={`colectie_save_${item.product_id}`} className={`data_noua_${item.product_id}`}  onclick="doMoveBeezee({item.product_id})">Muta in Beezee</button>
                 
                </div>
            )}
            </LazyLoad>
          </div>
          ))}
          <div id="products" className={`popup view ${isBoxVisible ? "true" : "false"}`}>
          <span className="cart-close1" role="button" aria-label="Close" onClick={e => closeTooltip(e)}><span aria-hidden="true"></span></span>
          <div className="mht">
          <Product product_id={sel_prod} product={prod_det} moneda={moneda}/> 
          </div>
        </div>
        </div>
        </div>
        </>
        }
       </>
      );
    }
  }
}

export default MyComponent;

